.title-container {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

@media only screen and (min-width: 601px) {
  .title-container {
      padding-top: 16vh;
      padding-bottom: 16vh;
  }
}

.title-container .title {
  position: relative;
  margin-bottom: -14px;
  padding-right: 5%;
  font-size: 64px;
  font-weight: 400;
  line-height: 1.3em;
  text-indent: -4px;
  color: rgb(33, 150, 243);
}

.title-container .title:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 2px;
  width: 64px;
  content: "";
  background: rgb(33, 150, 243);
}

.title-container .title-caption {
  max-width: 640px;
  padding-right: 10%;
  line-height: 38px;
  font-size: 28px;
  font-weight: 400;
}

.subhead-title-container {
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

.subhead-title-container .text {
  position: relative;
  margin-bottom: -14px;
  padding-right: 5%;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.3em;
  text-indent: -4px;
  width: 100%;
  color: rgb(33, 150, 243);
}

.subhead-title-container .text:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 2px;
  width: 84px;
  content: "";
  display: none;
  background: rgb(33, 150, 243);
}

@media only screen and (min-width: 601px) {
  .subhead-title-container {
      margin: 0;
      text-align: left;
  }
  .subhead-title-container .text:after {
      display: block;
  }
}

footer {
  margin-top: 48px;
  border-top: 1px solid #d9d9d9;
}

footer .f-container {
  margin-top: 24px;
}

footer .f-container .f-container-text {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
}

a {
  text-decoration: none;
  color: rgb(33, 150, 243);
}
